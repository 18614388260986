import { simplifiedNotes, simplify } from "./music-theory";
function createPiano() {
    var pianoDiv = document.getElementById("piano");
    if (!pianoDiv || pianoDiv.hasChildNodes()) {
        return false;
    }
    for (var i = 3; i <= 26; i++) {
        var key = simplifiedNotes[i % 12].replace("#", "sharp");
        var octave = Math.floor(i / 12);
        var keyDiv = document.createElement("div");
        keyDiv.classList.add('key', "key-".concat(key));
        keyDiv.classList.add(key.length === 1 ? "white-key" : "black-key");
        keyDiv.id = "key-".concat(key).concat(octave);
        pianoDiv.appendChild(keyDiv);
    }
    return true;
}
function getKeyElements(note, octave) {
    var noteName = simplify(note).replace("#", "sharp");
    if (octave === "all") {
        return document.getElementsByClassName("key-".concat(noteName));
    }
    else {
        return [document.getElementById("key-".concat(noteName).concat(octave))];
    }
}
function highlightKey(note, octave, className, on) {
    var keyElements = getKeyElements(note, octave);
    for (var i = 0; i < keyElements.length; i++) {
        var keyElement = keyElements[i];
        if (keyElement) {
            if (on) {
                keyElement.classList.add(className);
            }
            else {
                keyElement.classList.remove(className);
            }
        }
    }
}
var pressedNotes = new Set();
var onPressedNotesChanged = function (pressedNotes) { };
var onNotesHit = function (notes, callback) {
    var notesSet = new Set(notes.map(function (note) { return simplify(note); }));
    onPressedNotesChanged = function (pressedNotes) {
        if (pressedNotes.size === notesSet.size
            && Array.from(pressedNotes).every(function (note) { return notesSet.has(note); })) {
            onPressedNotesChanged = function (_) { };
            callback();
        }
    };
};
var setHighlightNoteColors = function (className, notes) {
    var simplified = notes.map(function (note) { return simplify(note); });
    for (var i = 0; i < simplifiedNotes.length; i++) {
        var note = simplifiedNotes[i];
        highlightKey(note, "all", className, simplified.includes(note));
    }
};
var setNotePressed = function (note, octave, pressed) {
    highlightKey(note, octave, "active", pressed);
    if (pressed) {
        pressedNotes.add(simplify(note));
    }
    else {
        pressedNotes.delete(simplify(note));
    }
    onPressedNotesChanged(pressedNotes);
};
export { createPiano, setNotePressed, setHighlightNoteColors, onNotesHit };
