var flatMap = new Map([
    ["A♯", "B♭"],
    ["C♯", "D♭"],
    ["D♯", "E♭"],
    ["F♯", "G♭"],
    ["G♯", "A♭"],
]);
var getDuplicateLetters = function (representation) {
    var letters = representation.map(function (note) { return note[0]; });
    return letters.filter(function (letter, index) { return letters.indexOf(letter) !== index; });
};
var getOptimalRepresentation = function (keySignature) {
    var sharpRepresentation = keySignature;
    var flatRepresentation = keySignature.map(function (note) { return flatMap.get(note) || note; });
    var sharpDuplicateLetters = getDuplicateLetters(sharpRepresentation);
    if (sharpDuplicateLetters.length == 0)
        return sharpRepresentation;
    var flatDuplicateLetters = getDuplicateLetters(flatRepresentation);
    if (flatDuplicateLetters.length == 0)
        return flatRepresentation;
    // now we need to try E#, B#, Cb, Fb
    if (sharpDuplicateLetters.includes("F") && !sharpRepresentation.includes("E"))
        sharpRepresentation = sharpRepresentation.map(function (note) { return note === "F" ? "E♯" : note; });
    if (sharpDuplicateLetters.includes("C") && !sharpRepresentation.includes("B"))
        sharpRepresentation = sharpRepresentation.map(function (note) { return note === "C" ? "B♯" : note; });
    if (getDuplicateLetters(sharpRepresentation).length == 0)
        return sharpRepresentation;
    if (flatDuplicateLetters.includes("E") && !flatRepresentation.includes("F"))
        flatRepresentation = flatRepresentation.map(function (note) { return note === "E" ? "F♭" : note; });
    if (flatDuplicateLetters.includes("B") && !flatRepresentation.includes("C"))
        flatRepresentation = flatRepresentation.map(function (note) { return note === "B" ? "C♭" : note; });
    if (getDuplicateLetters(flatRepresentation).length == 0)
        return flatRepresentation;
    // shouldn't happen for real keys but here we are
    if (sharpRepresentation.length < flatRepresentation.length)
        return sharpRepresentation;
    return flatRepresentation;
};
export { getOptimalRepresentation };
